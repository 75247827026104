// Guards //
import guards from './guards';

// Layout //
const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

// Default //
const UserDetails = () => import('../views/Consumer/UserDetails');
const CartDetails = () => import('../views/Consumer/cart/CartDetails');
const AddressDetails = () => import('../views/Consumer/Address/AddressList');
const Success = () => import('../views/Consumer/Success/SuccessPage');
// Shop Admin //
const shopCatalog = () => import('../views/ShopAdmin/ShopCatalog/ShopCatalog');
const shopOrders = () => import('../views/ShopAdmin/orders/ordersList');
const shopOrdersDetails = () => import('../views/ShopAdmin/orders/orderDetails');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/details/',
            name: 'UserDetails',
            component: UserDetails
        },
        {
            path: '/cart/',
            name: 'CartDetails',
            component: CartDetails
        },
        {
            path: '/address-list/',
            name: 'AddressDetails',
            component: AddressDetails
        },
        {
            path: '/success/',
            name: 'Success',
            component: Success
        },
        // Shop Admin //
        {
            beforeEnter: guards.adminInGuard,
            path: '/admin/catalog/',
            name: 'shopCatalog',
            component: shopCatalog
        },
        {
            beforeEnter: guards.adminInGuard,
            path: '/admin/orders/',
            name: 'shopOrders',
            component: shopOrders
        },
        {
            beforeEnter: guards.adminInGuard,
            path: '/admin/orders/:id/details/',
            name: 'shopOrdersDetails',
            component: shopOrdersDetails
        }
    ]
};
